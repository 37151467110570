 <template>
  <div>
    <h1 :class="$style.pageTitle">ユーザー 一覧 ({{ users.length }} 人)</h1>
    <section class="card">
      <div class="card-header">
        <div class="pull-right mt-2">
          <div class="d-inline-block">
            <router-link :to="{ name: 'userCreate' }">
              <a-button type="primary" class="ml-3" autoInsertSpaceInButton="false" :class="$style.filledBtn">作成</a-button>
            </router-link>
            <router-link :to="{ name: 'userCreateBulk' }">
              <a-button type="primary" class="ml-3" autoInsertSpaceInButton="false" :class="$style.filledBtn">一括作成</a-button>
            </router-link>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div v-if="userListLoading" class="text-center">
          <a-spin tip="Loading..." />
        </div>
        <div v-if="!userListLoading">
          <a-form layout="inline">
            <a-form-item label="名前">
              <form-multiple-select
                placeholder="名前"
                :candidates="users"
                style="min-width: 200px"
                @changeSelectedItems="changeSelectedName"
              />
            </a-form-item>
            <a-form-item label="権限">
              <a-select
                mode="multiple"
                placeholder="権限"
                :value="selectedRoles"
                style="min-width: 200px"
                @change="changeSelectedRole"
              >
                <a-select-option v-for="item in filteredOptions" :key="item.label" :value="item.label">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="所属支店">
              <form-multiple-select
                placeholder="所属支店"
                :candidates="branches"
                style="min-width: 200px"
                @changeSelectedItems="changeSelectedBranch"
              />
            </a-form-item>
          </a-form>

          <div v-if="hasMasterRole" class="mt-3 mb-2">
            <div class="d-inline-block">
              <a-button
                type="primary"
                @click="bulkUpdateModalVisible = true"
                :disabled="isSelectionalCommandDisabled"
                :loading="btnLoading"
                :class="$style.filledBtn"
              >
                一括処理する
              </a-button>
            </div>
          </div>

          <a-table
            :columns="columns"
            :data-source="filteredUsers"
            :pagination="false"
            rowKey="id"
            :rowClassName="(r, i) => i % 2 === 0 ? $style.stripe0 :  $style.stripe1"
          >
            <span slot="selectionTitle">
              <a-checkbox
                :indeterminate="isAllSelectionIndeterminate"
                :checked="isAllSelected"
                @change="onChangeAllSelection"
              />
            </span>
            <span slot="selection" slot-scope="_, record">
              <a-checkbox @change="onChangeRowSelection($event, record.id)" :checked="isRowSelected(record.id)" />
            </span>
            <span slot="name" slot-scope="name, record"><span v-if="myRole === 0">{{ record.id }}: </span><span v-if="record.unavailable === 1"><a-tag color="red">停止中</a-tag></span><router-link :to="{ name: 'userShow', params: { id: record.id }}">{{ name }}</router-link></span>
            <span slot="type_icon" slot-scope="type_icon, record">
              <span style="width: 18px; display: inline-block;"><i v-if="record.is_google2fa === 1" class="icmn-qrcode" style="color: #388E3C;"></i></span>
              <span v-if="showUserFleetFlag" style="width: 18px; display: inline-block;"><i v-if="record.fleet_flag === 0" class="fa fa-tachometer" style="color: #42a2e9;"></i></span>
              <span v-if="showUserRollCallAgent" style="width: 18px; display: inline-block;"><i v-if="record.roll_call_agent === 1" class="fa fa fa-phone-square" style="color: #fa8c16;"></i> </span>
              <span style="width: 18px; display: inline-block;"><i v-if="record.plus_flag === 1" class="fa fa-plus" style="color: #a11c3f;"></i></span>
              <span style="width: 18px; display: inline-block;"><i v-if="record.allow_driving_schedule === 1" class="fa fa-calendar-plus-o" style="color: #4b0082;"></i></span>
              <span style="width: 18px; display: inline-block;"><i v-if="record.interlocking_detector === 1" class="fa fa-bluetooth" style="color: #146DAD;"></i></span>
            </span>
            <span slot="role" slot-scope="role"><a-tag :color="roles[role].color">{{ roles[role].label }}</a-tag></span>
            <span slot="confirmation" slot-scope="confirmation">{{ confirmation }}</span>
            <span slot="lastLogin" slot-scope="lastLogin">{{ lastLogin | moment }}</span>
            <span slot="branch" slot-scope="branch, record">
              <a-button @click="showBranchModal(record)">所属</a-button>
            </span>
            <span slot="roll_call_remain" slot-scope="roll_call_remain, record">{{ record.roll_call_count - record.count_roll_call_agent }}</span>
            <span slot="roll_call_count" slot-scope="roll_call_count, record">{{ record.roll_call_count }}</span>
          </a-table>
          <p>
            種別
            <span><br><i class="icmn-qrcode" style="color: #388E3C;"></i>: 2段階認証</span>
            <span v-if="showUserFleetFlag"><br><i class="fa fa-tachometer" style="color: #42a2e9;"></i>: 解析対象</span>
            <span v-if="showUserRollCallAgent"><br><i class="fa fa fa-phone-square" style="color: #fa8c16;"></i>: 点呼代行対象</span>
            <span><br><i class="fa fa-plus" style="color: #a11c3f;"></i>: PLUS の使用</span>
            <span><br><i class="fa fa-calendar-plus-o" style="color: #4b0082;"></i>: 運行計画表の被閲覧許可</span>
            <span><br><i class="fa fa-bluetooth" style="color: #146DAD;"></i>: 連動検知器の使用</span>
          </p>
          <p class="pull-right">
            <a-button @click="exportCsv" style="margin-right: 10px;" :class="$style.filledBtn">CSV 出力</a-button>
            <router-link :to="{ name: 'driversLisenceCreateBulk' }">
              <a-button type="primary" autoInsertSpaceInButton="false" :class="$style.filledBtn">運転免許一括作成</a-button>
            </router-link>
          </p>
        </div>
      </div>
    </section>

    <!-- 所属支店表示モーダル -->
    <a-modal
      :title="modalTitle"
      :visible="modalVisible"
      :footer="null"
      @cancel="hideModal"
    >
      <ul class="user_branches">
        <li v-for="branch in userBranches" :key="branch.id">
          {{ branch.name }}
        </li>
      </ul>
    </a-modal>

    <!-- 一括更新選択モーダル -->
    <a-modal
      title="チェックされたユーザーの一括処理"
      :visible="bulkUpdateModalVisible"
      :footer="null"
      @cancel="bulkUpdateModalVisible = false"
    >
      <div>
        <a-button
          type="primary"
          @click="updateFlag('fleet_flag', 0)"
          :loading="btnLoading"
          :class="$style.filledBtn"
        >
          解析対象にする
        </a-button>
      </div>
      <div class="mt-4">
        <a-button
          type="primary"
          @click="updateFlag('fleet_flag', 1)"
          :loading="btnLoading"
          :class="$style.filledBtn"
        >
          解析対象にしない
        </a-button>
      </div>
      <div class="mt-4">
        <a-button
          type="primary"
          @click="updateFlag('allow_driving_schedule', 1)"
          :loading="btnLoading"
          :class="$style.filledBtn"
        >
          運行計画表の被閲覧を許可する
        </a-button>
      </div>
      <div class="mt-4">
        <a-button
          type="primary"
          @click="updateFlag('allow_driving_schedule', 0)"
          :loading="btnLoading"
          :class="$style.filledBtn"
        >
          運行計画表の被閲覧を許可しない
        </a-button>
      </div>
      <div class="mt-4">
        <a-button
          type="primary"
          @click="updateFlag('plus_flag', 1)"
          :loading="btnLoading"
          :class="$style.filledBtn"
        >
          PLUSを使用する
        </a-button>
      </div>
      <div class="mt-4">
        <a-button
          type="primary"
          @click="updateFlag('plus_flag', 0)"
          :loading="btnLoading"
          :class="$style.filledBtn"
        >
          PLUSを使用しない
        </a-button>
      </div>

      <div class="mt-4">
        <a-button
          type="primary"
          @click="updateFlag('unavailable', 0)"
          :loading="btnLoading"
          :class="$style.filledBtn"
        >
          アカウントを使用可能にする
        </a-button>
      </div>
      <div class="mt-4">
        <a-button
          type="primary"
          @click="updateFlag('unavailable', 1)"
          :loading="btnLoading"
          :class="$style.filledBtn"
        >
          アカウントを使用不可にする
        </a-button>
      </div>

      <div class="mt-4">
        <a-button
          type="primary"
          @click="updateFlag('interlocking_detector', 1)"
          :loading="btnLoading"
          :class="$style.filledBtn"
        >
          連動検知器を使用にする
        </a-button>
      </div>
      <div class="mt-4">
        <a-button
          type="primary"
          @click="updateFlag('interlocking_detector', 0)"
          :loading="btnLoading"
          :class="$style.filledBtn"
        >
          連動検知器を非使用にする
        </a-button>
      </div>
      <div class="mt-4">
        <a-form-model layout="inline">
          <a-form-model-item ref="number" label="値" prop="number">
            <a-input-number v-model="number" :min="-100" :max="100"/>
          </a-form-model-item>
          <a-button
            type="primary"
            @click="updateFlag('roll_call_count', 0)"
            :loading="btnLoading"
            :class="$style.filledBtn"
          >
            契約コール数を増減する
          </a-button>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>
<style lang="scss" module>
@import '@/assets/styles/mixins.scss';
</style>
<script>
import Vue from 'vue'
import moment from 'moment'
import { enumData } from '@/services/enum'
import FormMultipleSelect from '../../../components/FormComponents/MultipleSelect'

const OPTIONS = enumData.userRole.filter(u => u.value !== 0)
function getIsDuplicate(arr1, arr2) {
  return [...arr1, ...arr2].filter(item => arr1.includes(item) && arr2.includes(item)).length > 0
}

export default {
  components: {
    FormMultipleSelect,
  },
  data() {
    return {
      userListLoading: true,
      users: [],
      roles: [],
      branches: [],
      selectedUsers: [],
      selectedBranches: [],
      modalVisible: false,
      modalTitle: '',
      userBranches: [],
      myRole: 3,
      selectedRoles: [],
      showUserFleetFlag: 0,
      showUserRollCallAgent: 0,
      selectedUserIds: [], // チェックボックスで選択されたユーザーID
      freeItemUsers: {},
      number: 0,
      btnLoading: false,
      bulkUpdateModalVisible: false,
    }
  },
  computed: {
    columns() {
      const _columns = [
        {
          title: '名前',
          dataIndex: 'name',
          scopedSlots: { customRender: 'name' },
          sorter: (a, b) => {
            if (a.name > b.name) return 1
            else return -1
          },
        },
        { title: '種別', scopedSlots: { customRender: 'type_icon' } },
        { title: '権限', dataIndex: 'role', scopedSlots: { customRender: 'role' }, sorter: (a, b) => a.role - b.role },
        {
          title: 'ステータス',
          dataIndex: 'confirmation',
          scopedSlots: { customRender: 'confirmation' },
          sorter: (a, b) => {
            if (a.confirmation > b.confirmation) return 1
            else if (a.confirmation < b.confirmation) return -1
            else return 0
          },
        },
        {
          title: '最終ログイン時刻',
          dataIndex: 'last_login',
          scopedSlots: { customRender: 'lastLogin' },
          sorter: (a, b) => {
            const dateTimeA = new Date(a.last_login)
            const dateTimeB = new Date(b.last_login)
            if (dateTimeA > dateTimeB) return 1
            else if (dateTimeA < dateTimeB) return -1
            else return 0
          },
          align: 'center',
        },
        {
          title: '所属支店',
          dataIndex: 'branch',
          scopedSlots: { customRender: 'branch' },
        },
      ]

      if (this.showUserRollCallAgent) {
        _columns.push({
          title: '点呼代行残数',
          scopedSlots: { customRender: 'roll_call_remain' },
          sorter: (a, b) => {
            return (a.roll_call_count - a.count_roll_call_agent) - (b.roll_call_count - b.count_roll_call_agent)
          },
        })
        _columns.push({
          title: '契約コール数',
          scopedSlots: { customRender: 'roll_call_count' },
          sorter: (a, b) => {
            return a.roll_call_count - b.roll_call_count
          },
        })
      }

      if (this.hasMasterRole) {
        // 先頭に選択チェックボックス列を追加
        _columns.unshift({
          dataIndex: 'selection',
          slots: { title: 'selectionTitle' },
          scopedSlots: { customRender: 'selection' },
          sorter: false,
          align: 'center',
          width: 40,
        })
      }

      return _columns
    },
    filteredOptions() {
      return OPTIONS.filter(o => !this.selectedRoles.includes(o))
    },
    filteredUsers() {
      var restUsersData = this.users
      if (this.selectedRoles.length) restUsersData = restUsersData.filter(u => this.selectedRolesValue.includes(u.role))
      if (this.selectedUsers.length) restUsersData = restUsersData.filter(u => this.selectedUsers.includes(u.id))
      if (this.selectedBranches.length) restUsersData = restUsersData.filter(u => getIsDuplicate(this.selectedBranches, u.branch_ids))
      return restUsersData
    },
    selectedRolesValue() {
      return this.selectedRoles.map(v => enumData.userRole.find(target => target.label === v).value)
    },
    hasMasterRole() {
      return this.myRole === 0
    },
    // テーブルヘッダーのチェックボックスが未確定か？
    isAllSelectionIndeterminate() {
      return this.selectedUserIds.length > 0 &&
        this.users.length > this.selectedUserIds.length
    },
    // テーブルヘッダーのチェックボックスが選択されているか？
    isAllSelected() {
      return this.selectedUserIds.length > 0 &&
        this.users.length === this.selectedUserIds.length
    },
    // 一覧行の選択チェックボックスがチェックされているか？
    isRowSelected() {
      return (id) => {
        return this.selectedUserIds.findIndex((i) => i === id) >= 0
      }
    },
    // 選択ユーザーに対する処理が実行可能か？
    isSelectionalCommandDisabled() {
      return this.selectedUserIds.length === 0
    },
  },
  beforeMount() {
    Vue.prototype.$api.send('get', 'user').then(myResponse => {
      this.myRole = myResponse.role
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 自身の情報取得に失敗しました。',
        })
      })

    const userList = Vue.prototype.$api.send('get', 'users_for_list')
    userList.then(response => {
      response = response.sort(function(a, b) {
        return (a.unavailable < b.unavailable) ? -1 : 1
      })
      this.users = response.map(u => {
        if (u.last_login !== null) {
          u.confirmation = 'active'
        } else {
          u.confirmation = 'inactive'
        }
        if (!u.mobile_model) u.mobile_model = ''
        if (!u.mobile_sim) u.mobile_sim = ''
        u.branch_ids = u.branches.map(b => b.id)

        return u
      })

      this.userListLoading = false
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': ユーザーの取得に失敗しました。',
        })
        this.userListLoading = false
      })
    enumData.userRole.forEach(u => { this.roles[u.value] = { label: u.label, color: u.tag } })

    const companyRequest = Vue.prototype.$api.send('get', 'company')
    companyRequest.then(response => {
      this.showUserFleetFlag = response.show_user_fleet_flag
      this.showUserRollCallAgent = response.show_user_roll_call_agent
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 会社情報の取得に失敗しました。',
        })
      })

    const branchList = Vue.prototype.$api.send('get', 'branches')
    branchList.then(response => {
      this.branches = response
      this.branchListLoading = false
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 支店の取得に失敗しました。',
        })
        this.branchListLoading = false
      })
  },
  filters: {
    moment: function (date) {
      if (date) {
        return moment(date).format('YYYY/MM/DD HH:mm')
      } else {
        return '-'
      }
    },
  },
  created() {
    const resultFreeItem = Vue.prototype.$api.send('get', 'free_item_users')
    resultFreeItem.then(response => {
      response.forEach(i => {
        this.freeItemUsers[i.id] = i.name
      })
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': ユーザーの自由項目の取得に失敗しました。',
        })
        this.loading = false
      })
  },
  methods: {
    changeSelectedRole(selectedRoles) { this.selectedRoles = selectedRoles },
    changeSelectedName(vals) { this.selectedUsers = vals },
    changeSelectedBranch(vals) { this.selectedBranches = vals },
    exportCsv() {
      const _this = this
      let csv = '\ufeff'
      csv += 'id,名前,ふりがな,権限,ステータス,最終ログイン時刻,メールアドレス,スマホ機種,スマホSIM,電話番号,所属支店,2段階認証'
      if (this.showUserFleetFlag) csv += ',解析対象'
      if (this.showUserRollCallAgent) csv += ',点呼代行対象,契約コール数,点呼代行残数'
      for (var fi in this.freeItemUsers) {
        csv += ',' + this.freeItemUsers[fi]
      }
      csv += '\n'
      this.users.forEach(u => {
        csv += u['id'] + ',' + u['name'] + ',' + u['phonetic'] + ',' + _this.roles[u.role].label + ',' + u['confirmation'] + ','
        if (u['last_login']) csv += u['last_login']
        csv += ',' + u['email'] + ',' + u['mobile_model'] + ',' + u['mobile_sim'] + ',' + u['tel'] + ','
        u.branches.forEach(function (b, i) {
          csv += b.name
          if (i !== u.branches.length - 1) csv += '/'
        })
        csv += ',' + u['is_google2fa']
        if (this.showUserFleetFlag) csv += ',' + ((u['fleet_flag'] + 1) % 2)
        if (this.showUserRollCallAgent) csv += ',' + u['roll_call_agent'] + ',' + u['roll_call_count'] + ',' + (u['roll_call_count'] - u['count_roll_call_agent'])
        for (var fi in this.freeItemUsers) {
          const result = u['free_item_user_content']['data'].find(e => Number(e.free_item_user_id) === Number(fi))
          csv += ','
          if (result) csv += result['data']
        }
        csv += '\n'
      })
      var blob = new Blob([csv], { type: 'text/plain' })
      var link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'users.csv'
      link.click()
    },
    // ユーザーの所属支店をモーダルで表示する
    showBranchModal(user) {
      this.modalTitle = user.name + 'の所属支店'
      this.userBranches = user.branches
      this.modalVisible = true
    },
    hideModal() {
      this.modalVisible = false
    },
    // テーブルヘッダーのチェックボックス変更時
    onChangeAllSelection(e) {
      this.selectedUserIds = e.target.checked
        // すべてチェックON
        ? this.users.map(u => u.id)
        // すべてチェックOFF
        : []
    },
    // 一覧行の選択チェックボックス変更時
    onChangeRowSelection(e, id) {
      if (e.target.checked) {
        this.selectedUserIds.push(id)
      } else {
        // チェックOFF: selectedUserIdsから削除
        const index = this.selectedUserIds.findIndex((i) => i === id)
        if (index >= 0) {
          this.selectedUserIds.splice(index, 1)
        }
      }
    },
    // 選択されたユーザーのフラグを一括更新する
    // target: fleet_flag, allow_driving_schedule
    async updateFlag(target, value) {
      try {
        await this.postUserBatchProcess({
          user_ids: this.selectedUserIds,
          target: target,
          value: value,
          number: this.number,
        })
        // 種別列の更新
        this.users = this.users.map((user) => {
          if (this.selectedUserIds.includes(user.id)) {
            if (target !== 'roll_call_count') {
              user[target] = value
            } else if (target === 'roll_call_count') {
              user.roll_call_count += this.number
              if (user.roll_call_count < 0) {
                user.roll_call_count = 0
              }
            }
          }
          return user
        })
      } catch (error) {
      } finally {
        this.bulkUpdateModalVisible = false
      }
    },
    async postUserBatchProcess(body) {
      this.btnLoading = true
      try {
        await Vue.prototype.$api.send('post', 'users/batch_process', body)
        this.$notification['success']({
          message: '更新しました。',
        })
      } catch (error) {
        console.error(error)
        this.$notification['error']({
          message: error.status + ': 更新に失敗しました。',
        })
        throw error
      } finally {
        this.btnLoading = false
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.user_branches {
  li {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
}
</style>
